import React from 'react';

const Home = () => {
    return (
        <div className="content">
            <h1>Welcome to TieTheDay</h1>
            <p>Your one-stop solution for all Event planning needs.</p>
        </div>
    );
};

export default Home;
