// index.js
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import { Provider } from 'react-redux'; // Import Provider
import { store } from './Utilities/store'; // Import your configured Redux store
import App from './App'; // Import your App component
import './styles/styles.css';


// Get the root element from the DOM
const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // Create a root

// Render the app wrapped in Provider
root.render(
    <Provider store={store}> {/* Wrap your App with Provider */}
        <App />
    </Provider>
);
