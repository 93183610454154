import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Images/navbar.jsx';
import  './styles/styles.css'
import Footer from './components/Images/footer';
import Home from './components/home';

import VendorList from './components/VendorList';
import VendorDetails from './components/VendorDetails';
import About from './pages/About';
import Contact from './pages/Contact';
import Register from './pages/Register';

const App = () => {
  return (
    <Router>
      {/* Navbar will be displayed on all pages */}
      <Navbar />

      {/* Define routes for different pages */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/vendors" element={<VendorList />} />
        <Route path="/vendors/:id" element={<VendorDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/register" element={<Register />} />
      </Routes>

      {/* Footer will be displayed on all pages */}
      <Footer />
    </Router>
  );
};

export default App;
