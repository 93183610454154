import React, { useState, useEffect } from 'react';
import './Navbar.css'; // Include the CSS file for styling
import logo from './ttd_logo.png'


const Navbar = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Fetch categories from the backend
        fetch('http://tietheday.com/vendors')
            .then((response) => response.json())
            .then((data) => setCategories(data))
            .catch((error) => console.error('Error fetching categories:', error));
    }, []);

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <img src={logo} alt="Logo" className="navbar-logo" />
                <div className="navbar-title">
                    <h1>TieTheDay</h1>
                    <p>A company owned by Shivam Rajpoot</p>
                </div>
            </div>
            <div className="navbar-links">
                <div className="dropdown">
                    <a href="#" className="dropdown-toggle">Vendors ▾</a>
                    <div className="dropdown-menu">
                        <div className="dropdown-grid">
                            {categories.map((category, index) => (
                                <a key={index} href={`/vendors/${category.category_name.toLowerCase().replace(/\s+/g, '-')}`}>
                                    {category.category_name}
                                </a>
                            ))}
                        </div>
                        {/* <div className="dropdown-footer">
                            <a href="/vendor/login" className="vendor-link">I am a vendor</a>
                            <a href="/vendor/register" className="register-button">Register now</a>
                        </div> */}
                    </div>
                </div>
                <a href="/blog">Blog</a>
                <a href="/search"><span className="search-icon">🔍</span> Search</a>
                <a href="/login">Login</a>
                <a href="/vendor" className="highlight-link">Are you a vendor?</a>
            </div>
        </nav>
    );
};

export default Navbar;
